@font-face {
    font-family: 'bold';
    src: url('../assets/opensans-bold-webfont.woff2') format('woff2'),
         url('../assets/opensans-bold-webfont.woff') format('woff'),
         url('../assets/opensans-bold-webfont.ttf') format('truetype'),
         url('../assets/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'light';
    src: url('../assets/opensans-light-webfont.woff2') format('woff2'),
         url('../assets/opensans-light-webfont.woff') format('woff'),
         url('../assets/opensans-light-webfont.ttf') format('truetype'),
         url('../assets/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'regular';
    src: url('../assets/opensans-regular-webfont.woff2') format('woff2'),
         url('../assets/opensans-regular-webfont.woff') format('woff'),
         url('../assets/opensans-regular-webfont.ttf') format('truetype'),
         url('../assets/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'semibold';
    src: url('../assets/opensans-semibold-webfont.woff2') format('woff2'),
         url('../assets/opensans-semibold-webfont.woff') format('woff'),
         url('../assets/opensans-semibold-webfont.ttf') format('truetype'),
         url('../assets/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

h1 {
    font-family: 'bold';
}